import api from "@/modules/api";

const VERBOSE = true


export async function get(path) {
    const req = await api.get(path)
    if (req.ok) {
        if (VERBOSE) console.log("GET ", path, "status", req.ok ? "ok" : "fail", "result", req.payload)
        return req.payload
    } else {
        const err = 'Ошибка загрузки данных ' + path
        console.error(err)
    }
}

/**
 *
 * @returns {Promise<Object>}
 */
export async function post(path, obj, querySpec) {
    const req = await api.postJson(path, obj, querySpec)
    if (req.ok) {
        if (VERBOSE) console.log("GET ", path, "status", req.ok ? "ok" : "fail", "result", req.payload)
        return req.payload
    } else {
        const err = 'Ошибка cохранения данных ' + path
        console.error(err)
    }
}

export async function loadRegionalDashboardData(quarter, regionCode) {
    return await get('iccdata/regvalues/' + quarter + '/' + regionCode) || {}
}


export async function loadCznDashboardData(quarter, cznId) {
    return await get('iccdata/cznvalues/' + quarter + '/' + cznId) || {}
}

export async function loadConfigByQuarter(quarter) {
    if (quarter >= 4) {
        return JSON.parse(JSON.stringify(configv1_1))
    } else if (quarter < 4) {
        return JSON.parse(JSON.stringify(configv1_0))
    }
}

export async function getIccChecklistBook(config, region, department, values, currentPeriod) {
    let book = getBookLayout()
    let uniquePeriods = [...new Set(values.map(e=>e.quarter))].sort().reverse()
    if(uniquePeriods.length === 0) uniquePeriods = [currentPeriod]
    console.log('debug', values.map(e=>e.period))
    book.sheets = uniquePeriods.map(period => getChecklistSheet(config, region, department, values, period))
    return book
}

function getQuarterName(period){
    const startYear = 2023
    const yearsPassed = Math.floor((period-1)/4)
    const year = startYear + yearsPassed
    const quarter = period-yearsPassed*4
    return quarter + ' квартал ' + year + ' года'
}

function getChecklistSheet(config, region, department, values, period) {
    console.log(config)
    let sheet = {
        name: getQuarterName(period),
        displayGridLines: true,
        columns: [
            {
                index: 1,
                width: 7500
            },
            {
                index: 2,
                width: 12000
            },
            {
                index: 3,
                width: 12000
            },
            {
                index: 4,
                width: 5000
            },
            {
                index: 5,
                width: 12000
            }

        ],
        rows: [
            {
                index: 0,
                height: 1500,
                cells: [
                    {
                        index: 0,
                        value: 'Данные чеклиста клиентоцентричности за ' + getQuarterName(period) + '\nСубъект РФ: ' + region.name + '\nЦЗН: ' + department.fullName,
                        styleName: 'indexGroupTitle',
                        colspan: 7
                    }
                ]
            },
            {
                index: 3,
                cells: [
                    {
                        index: 0,
                        value: 'п/п',
                        styleName: 'indexGroupTitle',
                    },
                    {
                        index: 1,
                        value: 'Показатель',
                        styleName: 'indexGroupTitle',
                    },
                    {
                        index: 2,
                        value: 'Значение',
                        styleName: 'indexGroupTitle',
                    },
                    {
                        index: 3,
                        value: 'Приложения',
                        styleName: 'indexGroupTitle',
                    },
                    {
                        index: 4,
                        value: 'Контроль',
                        styleName: 'indexGroupTitle',
                    },
                    {
                        index: 5,
                        value: 'Комментарий',
                        styleName: 'indexGroupTitle',
                    },
                    {
                        index: 6,
                        value: 'Ручная оценка',
                        styleName: 'indexGroupTitle',
                    }
                ]
            }
        ]
    }
    let curRow = 4;
    for (let i = 0; i < config.configParams.indexGroups.length; i++) {
        let indexGroup = config.configParams.indexGroups[i]
        sheet.rows.push({
            index: curRow,
            cells: [
                {
                    index: 0,
                    value: (i+1) + '.',
                    styleName: 'indexGroupTitle',
                },
                {
                    index: 1,
                    value: indexGroup.title,
                    styleName: 'indexGroupTitle',
                    colspan: 6,
                }
            ]
        })
        curRow++
        for(let j = 0; j < indexGroup.indices.length; j++){
            let index = indexGroup.indices[j]
            sheet.rows.push({
                index: curRow,
                cells: [
                    {
                        index: 0,
                        value: (i+1) + '.' + (j+1) + '.',
                        styleName: 'indexTitle',
                    },
                    {
                        index: 1,
                        value: index.title,
                        styleName: 'indexTitle',
                        colspan: 6
                    }
                ]
            })
            curRow++
            for(let k = 0; k < index.parameters.length; k++){
                let parameter = index.parameters[k]
                let value = getValue(values, indexGroup.id, index.id, parameter.id, period)
                let previousValue = getValue(values, indexGroup.id, index.id, parameter.id, period-1)
                let rowspan = value?.attachments?.length || 1
                sheet.rows.push({
                    index: curRow,
                    cells: [
                        {
                            index: 0,
                            value: (i+1) + '.' + (j+1) + '.' + (k+1) + '.',
                            styleName: 'parameterStyle',
                            rowspan
                        },
                        {
                            index: 1,
                            value: parameter.title + (index.questionType === 'DYNAMIC' ? k===0 ? ' (текущие данные)' : ' (предыдущий период)' : ''),
                            styleName: 'parameterStyle',
                            rowspan
                        },
                        {
                            index: 2,
                            value: value?.comment ||
                                (index.questionType === 'DYNAMIC' ? k===0 ? value?.currentValue : previousValue?.currentValue : '-'),
                            styleName: 'parameterStyle',
                            rowspan
                        },
                        {
                            index: 3,
                            value: value?.attachments?.[0]?.name || '-',
                            link: value?.attachments?.[0] ? 'https://new.czn-office.ru/api/uuidfs/' + value.attachments[0].id : undefined,
                            styleName: value?.attachments?.[0] ? 'link' : 'parameterStyle',
                        },
                        {
                            index: 4,
                            styleName: value?.controlInfo?.flag===3 ? 'redCheckResult' : value?.controlInfo?.flag===2 ? 'yellowCheckResult' : value?.controlInfo?.flag===1 ? 'greenCheckResult' : 'greyCheckResult',
                            rowspan
                        },
                        {
                            index: 5,
                            value: value?.controlInfo?.comment,
                            styleName: 'parameterStyle',
                            rowspan
                        },
                        {
                            index: 6,
                            value: value?.controlInfo?.score,
                            styleName: 'parameterStyle',
                            rowspan
                        }
                    ]
                })
                curRow++
                for(let l = 1; l < rowspan; l++){
                    sheet.rows.push({
                        index: curRow,
                        cells: [
                            {
                                index: 3,
                                value: value.attachments[l].name,
                                link: 'https://new.czn-office.ru/api/uuidfs/' + value.attachments[l].id,
                                styleName: 'link',
                            }]
                    })
                    curRow++
                }
            }
        }
    }

    return sheet
}

function getBookLayout() {
    return {
        name: "Чеклист",
        fonts: {
            default: {
                name: 'Times New Roman',
                size: 12,
            },
            boldDefault: {
                name: 'Times New Roman',
                size: 12,
                bold: true
            },
            link: {
                name: 'Times New Roman',
                size: 12,
                color: "#0000ff"
            }
        },
        styles: {
            indexGroupTitle: {
                font: 'boldDefault',
                wrapText: true,
                verticalAlignment: 'TOP',
                horizontalAlignment: 'LEFT',
                backgroundColor: '#E3E3E3',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            indexTitle: {
                font: 'default',
                wrapText: true,
                verticalAlignment: 'TOP',
                horizontalAlignment: 'LEFT',
                backgroundColor: '#E3E3E3',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            parameterStyle: {
                font: 'default',
                wrapText: true,
                verticalAlignment: 'TOP',
                horizontalAlignment: 'LEFT',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            link: {
                font: 'link',
                wrapText: true,
                verticalAlignment: 'TOP',
                horizontalAlignment: 'CENTER',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            redCheckResult: {
                backgroundColor: '#D0312D',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            yellowCheckResult: {
                backgroundColor: '#FFDF00',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            greenCheckResult: {
                backgroundColor: '#5DBB63',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },
            greyCheckResult: {
                backgroundColor: '#E0E0E0',
                borderTop: 'THIN',
                borderLeft: 'THIN',
                borderBottom: 'THIN',
                borderRight: 'THIN'
            },

        },
        sheets: []

    }
}

function getValue(checklistValues, groupId, indexId, parameterId, quarter) {
    return checklistValues.find(
        e => e.quarter === quarter && e.groupId === groupId && e.indexId === indexId && e.parameterId === parameterId
    )
}

const configv1_0 = {
    "id": 945966,
    "configParams": {
        "indexGroups": [
            {
                "id": 100,
                "title": "Показатели дружелюбия и партнерства",
                "indices": [
                    {
                        "id": 100,
                        "title": "Открытость руководства",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Проведение дней, когда руководство «меняется» рабочими местами с сотрудниками приема",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param1"
                            },
                            {
                                "id": 200,
                                "title": "Наличие открытых приемных дней / часов для граждан",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param2"
                            },
                            {
                                "id": 300,
                                "title": "Проведение публичных мероприятий руководства ЦЗН с гражданами",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param3"
                            },
                            {
                                "id": 400,
                                "title": "Публично доступная электронная почта с возможностью обратиться напрямую к руководителю",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param4"
                            },
                            {
                                "id": 500,
                                "title": "Публично доступный номер мобильного / стационарного телефона руководства",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            },
            {
                "id": 200,
                "title": "Показатели полезности обращения в СЗН",
                "indices": [
                    {
                        "id": 100,
                        "title": "Динамика роста базы вакансий",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Количество вакансий за отчетный период",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index1_param1_current"
                            },
                            {
                                "id": 100,
                                "title": "Количество вакансий за отчетный период",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index1_param1_previous"
                            }
                        ],
                        "questionType": "DYNAMIC",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 200,
                        "title": "Проведение мероприятий, стимулирующих рост числа соискателей",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Ярмарка вакансий",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param1"
                            },
                            {
                                "id": 200,
                                "title": "Подача объявлений о ЦЗН в СМИ",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param2"
                            },
                            {
                                "id": 300,
                                "title": "Акции в социальных сетях",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param3"
                            },
                            {
                                "id": 400,
                                "title": "Дни общения с руководством",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param4"
                            },
                            {
                                "id": 500,
                                "title": "Открытый отбор на вакансии конкретного востребованного работодателя",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param5"
                            },
                            {
                                "id": 600,
                                "title": "Деловые игры",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param6"
                            },
                            {
                                "id": 700,
                                "title": "Бизнес консультации по открытию собственного дела",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param7"
                            },
                            {
                                "id": 800,
                                "title": "День открытых дверей в СЗН с презентацией предоставляемых услуг и сервисов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param8"
                            },
                            {
                                "id": 900,
                                "title": "Экскурсии на предприятия региона",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param9"
                            },
                            {
                                "id": 1000,
                                "title": "И прочие мероприятия",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param10"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            },
            {
                "id": 300,
                "title": "Показатели, характеризующие минимизацию усилий клиента",
                "indices": [
                    {
                        "id": 100,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН в офисе ЦЗН",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Доступ к информационным стендам/стойкам беспрепятственный (прочесть объявления можно с близкого расстояния, стенды интуитивно легко найти / в ЦЗН есть навигация к инфостендам), в том числе для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param1"
                            },
                            {
                                "id": 200,
                                "title": "Информация на стендах актуальна на момент оценки по показателю (проверка на предмет актуальности осуществляется не менее чем 1 раз в 3 месяца)\nПоследняя дата проверки актуальности и обновления информации - указывается дата в АИС",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param2"
                            },
                            {
                                "id": 300,
                                "title": "Соблюдение единого оформления стендов в корпоративном стиле (предпочтительно в соответствии с брендбуком «Работа России») в каждом документе на стенде (шрифты, цвета, фирменные элементы и пр.).",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param3"
                            },
                            {
                                "id": 400,
                                "title": "Информация на инфостендах удобно сгруппирована и структурирована по тематическим разделам (информация различной тематики размещена в различных информационных полях (например, объявления о работе ЦЗН и реклама находятся в разных секциях)), есть заголовки",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param4"
                            },
                            {
                                "id": 500,
                                "title": "Информационные тексты стенда проанализированы на сайте https://glvrd.ru",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 200,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН на региональном сайте",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Сайт СЗН легко найти в популярных поисковиках (Яндекс, Google) (сайт находится в 3 первых позициях поисковой выдачи при введении запросов «ЦЗН региона», «центр занятости региона», «служба занятости региона»)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param1"
                            },
                            {
                                "id": 200,
                                "title": "На сайте имеется минимально необходимая информация о СЗН и ЦЗН, в т.ч. перечень и описание государственных услуг и сервисов в сфере занятости, режимы работы отделений и адреса, контактные данные СЗН и ЦЗН, ссылка на сайт «Работа в России», ссылка на сайт ОИВ, курирующих деятельность государственной службы занятости в  субъекте РФ",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param2"
                            },
                            {
                                "id": 300,
                                "title": "Пользователи сайта могут найти минимально необходимую информацию интуитивно и быстро, в т.ч. с помощью навигации",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param3"
                            },
                            {
                                "id": 400,
                                "title": "Сайт адаптирован для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param4"
                            },
                            {
                                "id": 500,
                                "title": "Информационные тексты сайта проанализированы на сайте https://glvrd.ru",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 300,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН в социальных сетях",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Аккаунты ЦЗН в социальных сетях открыты, не требуют подачу заявок и одобрения со стороны администраторов",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param1"
                            },
                            {
                                "id": 200,
                                "title": "Комментарии к публикациям открыты/есть возможность написать сообществу, при это ЦЗН регулярно и своевременно отвечает на комментарии граждан",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param2"
                            },
                            {
                                "id": 300,
                                "title": "Контент, в т.ч. картинки к постам, оформлен в фирменном стиле «Работа в России», визуально считывается",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param3"
                            },
                            {
                                "id": 400,
                                "title": "В неделю размещается не менее 3 постов в одной из социальных сетей (указать, в какой)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param4"
                            },
                            {
                                "id": 500,
                                "title": "Информационные тексты аккаунта социальной сети проанализированы на сайте https://glvrd.ru",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 400,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН в контакт-центре",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Информация о контакт-центре доступна во всех каналах взаимодействия с гражданами (в офисе ЦЗН, на сайте, в социальных сетях), в том числе информация адаптирована для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param1"
                            },
                            {
                                "id": 200,
                                "title": "Дозвон до контакт-центра составляет не более 3-4 гудков. Количество попыток дозвона не превышает 3 раз",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param2"
                            },
                            {
                                "id": 300,
                                "title": "Наличие сценариев (скриптов) для операторов контакт-центра по всем услугам и сервисам СЗН (обеспечение точности информации, сценарии проходят проверку на актуальность не реже, чем 1 раз в 3 месяца (необходимо указать дату создания/пересмотра/изменения скриптов и приложить соответствующие подтверждающие документы (при наличии)))",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param3"
                            },
                            {
                                "id": 400,
                                "title": "При соединении оператор первым поздоровался и представился, в т.ч. озвучил отделение ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param4"
                            },
                            {
                                "id": 500,
                                "title": "Количество шагов для получения консультации у определенного специалиста не превышает 2 (человеку, чтобы получить необходимую консультацию, нужно пройти не более 2 переключений между различными специалистами ЦЗН)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 500,
                        "title": "Многоканальность и омниканальность взаимодействия (определяется наличие различных способов (каналов) взаимодействия с клиентом",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Форма обратной связи на региональном сайте СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param1"
                            },
                            {
                                "id": 200,
                                "title": "Онлайн-чат с консультантом на региональном сайте СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param2"
                            },
                            {
                                "id": 300,
                                "title": "Контакт-центр",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param3"
                            },
                            {
                                "id": 400,
                                "title": "Аккаунты в социальных сетях",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param4"
                            },
                            {
                                "id": 500,
                                "title": "Мессенджеры",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param5"
                            },
                            {
                                "id": 600,
                                "title": "Чат-боты",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param6"
                            },
                            {
                                "id": 700,
                                "title": "Книга жалоб и предложений",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param7"
                            },
                            {
                                "id": 800,
                                "title": "E-mail",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param8"
                            },
                            {
                                "id": 900,
                                "title": "Push-рассылка",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param9"
                            },
                            {
                                "id": 1000,
                                "title": "СМС-информирование и иное (при направлении в адрес ФЦК СЗ ВНИИ труда описания канала взаимодействия, не входящего в перечень)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param10"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 600,
                        "title": "Быстрое реагирование на запрос клиента",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Наличие в ЦЗН системы анализа показателей скорости обслуживания клиентов (замеров времени ожидания в очереди, статистики контакт-центра, времени оказания услуг)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index6_param1"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 700,
                        "title": "Возможность предварительной записи на прием",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Офис ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param1"
                            },
                            {
                                "id": 200,
                                "title": "Сайт региональной СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param2"
                            },
                            {
                                "id": 300,
                                "title": "Аккаунты ЦЗН в социальных сетях",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param3"
                            },
                            {
                                "id": 400,
                                "title": "Контакт-центр ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param4"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            },
            {
                "id": 400,
                "title": "Показатели внешнего комфорта и удобства",
                "indices": [
                    {
                        "id": 100,
                        "title": "Внешний вид сотрудников",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Белый верх, черный или темно-синий низ униформы (п. 5.1 Брендбука) (блуза/рубашка с длинным рукавом, юбка / брюки прямого кроя, колготки телесного цвета, если туфли – то закрытые, для мужчин допустимы только классические ботинки)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index1_param1"
                            },
                            {
                                "id": 200,
                                "title": "Наличие платка для женщин, галстука/бабочки у мужчин, нагрудного значка с фирменным паттерном «Работа в России» (п. 5.1 Брендбука)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index1_param2"
                            },
                            {
                                "id": 300,
                                "title": "Макияж сотрудников не должен быть слишком ярким, используемые духи не должны иметь резкий запах; волосы должны быть аккуратно подстрижены и расчесаны или убраны в хвост; руки должны быть чистыми, ногти аккуратно подстрижены (разумной длины)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index1_param3"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 200,
                        "title": "Транспортная доступность",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Офис/точка присутствия находится в пешей доступности от остановок общественного транспорта (не более 15 минут)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param1"
                            },
                            {
                                "id": 200,
                                "title": "Наличие навигации к ЦЗН на остановках общественного транспорта/в значимых общественных местах",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param2"
                            },
                            {
                                "id": 300,
                                "title": "Наличие доступной парковки на территории ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param3"
                            },
                            {
                                "id": 400,
                                "title": "Наличие парковочных мест для инвалидов на территории ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param4"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 300,
                        "title": "Комфортность условий для получения услуг и сервисов",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Оформление внутреннего помещения",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param1"
                            },
                            {
                                "id": 200,
                                "title": "Горизонтальная фасадная вывеска «Работа в России» размещена на фасаде",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param2"
                            },
                            {
                                "id": 300,
                                "title": "Информационная табличка (режим работы) размещена у входа в ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param3"
                            },
                            {
                                "id": 500,
                                "title": "Установлены скамейки",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param5"
                            },
                            {
                                "id": 600,
                                "title": "Установлены урны",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param6"
                            },
                            {
                                "id": 700,
                                "title": "Освещение фасада в соответствии с брендбуком",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param7"
                            },
                            {
                                "id": 800,
                                "title": "Уличная навигация",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param8"
                            },
                            {
                                "id": 900,
                                "title": "Информационная стелла установлена на улице",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param9"
                            },
                            {
                                "id": 1000,
                                "title": "Флаги «Работа в России» установлены снаружи здания ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param10"
                            },
                            {
                                "id": 1100,
                                "title": "Обустроена велопарковка",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param11"
                            },
                            {
                                "id": 1200,
                                "title": "Обустроен вход для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param12"
                            },
                            {
                                "id": 1300,
                                "title": "Размещены витринные постеры",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param13"
                            },
                            {
                                "id": 1400,
                                "title": "Отделка входной группы в бренде «Работа в России»",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param14"
                            },
                            {
                                "id": 1500,
                                "title": "Стойка администратора в бренде «Работа в России»",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param15"
                            },
                            {
                                "id": 1600,
                                "title": "Доступ для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param16"
                            },
                            {
                                "id": 1700,
                                "title": "Установлена электронная очередь",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param17"
                            },
                            {
                                "id": 1800,
                                "title": "Оборудован гардероб (для небольших ЦЗН – напольные вешалки)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param18"
                            },
                            {
                                "id": 1900,
                                "title": "Оборудована мини-зона ожидания при входе (зеркало, кресла)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param19"
                            },
                            {
                                "id": 2000,
                                "title": "Оборудована колясочная",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param20"
                            },
                            {
                                "id": 2100,
                                "title": "Компьютеры и столы размещены в цифровой зоне",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param21"
                            },
                            {
                                "id": 2200,
                                "title": "Стулья размещены в цифровой зоне",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param22"
                            },
                            {
                                "id": 2300,
                                "title": "Наличие тактильных индикаторов для граждан с инвалидностью в цифровой зоне",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param23"
                            },
                            {
                                "id": 2400,
                                "title": "Отделение цифровой зоны прозрачными перегородками",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param24"
                            },
                            {
                                "id": 2500,
                                "title": "Оформлены места для ожидания, в т.ч. для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param25"
                            },
                            {
                                "id": 2600,
                                "title": "Информационные стенды установлены в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param26"
                            },
                            {
                                "id": 2700,
                                "title": "Кулер установлен в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param27"
                            },
                            {
                                "id": 2800,
                                "title": "Кондиционер установлен в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param28"
                            },
                            {
                                "id": 2900,
                                "title": "Место приема пищи, кофе-поинт установлены в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param29"
                            },
                            {
                                "id": 3000,
                                "title": "Оборудован детский уголок",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param30"
                            },
                            {
                                "id": 3100,
                                "title": "Оборудован коворкинг для клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param31"
                            },
                            {
                                "id": 3200,
                                "title": "Оборудованы переговорные для клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param32"
                            },
                            {
                                "id": 3300,
                                "title": "Оформлены окна приема клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param33"
                            },
                            {
                                "id": 3400,
                                "title": "Организовано пространство для коллективной работы / конференц-зал для клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param34"
                            },
                            {
                                "id": 3500,
                                "title": "Организована зона индивидуальной работы с гражданами",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param35"
                            },
                            {
                                "id": 3600,
                                "title": "Организована зона индивидуальной работы с гражданами с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param36"
                            },
                            {
                                "id": 3700,
                                "title": "Организован конференц-зал для сотрудников (отдельный)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param37"
                            },
                            {
                                "id": 3800,
                                "title": "Организована зона для групповых занятий и тренингов (отдельная)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param38"
                            },
                            {
                                "id": 3900,
                                "title": "Организованы кабинеты узких специалистов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param39"
                            },
                            {
                                "id": 4000,
                                "title": "Туалет (раздельный)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param40"
                            },
                            {
                                "id": 4100,
                                "title": "Туалет для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param41"
                            },
                            {
                                "id": 4200,
                                "title": "Комната матери и ребенка",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param42"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            }
        ]
    }
}

const configv1_1 = {
    "id": 945967,
    "configParams": {
        "indexGroups": [
            {
                "id": 100,
                "title": "Показатели дружелюбия и партнерства",
                "indices": [
                    {
                        "id": 100,
                        "title": "Открытость руководства",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Проведение дней, когда руководство территориального ЦЗН «меняется» рабочими местами с сотрудниками приема",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param1"
                            },
                            {
                                "id": 200,
                                "title": "Наличие открытых приемных дней / часов руководства территориального ЦЗН для граждан",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param2"
                            },
                            {
                                "id": 300,
                                "title": "Проведение публичных мероприятий руководства территориального ЦЗН с гражданами",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param3"
                            },
                            {
                                "id": 400,
                                "title": "Публично доступная электронная почта руководителя территориального ЦЗН с возможностью обратиться напрямую к руководителю",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param4"
                            },
                            {
                                "id": 500,
                                "title": "Публично доступный номер мобильного / стационарного телефона руководителя территориального ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup1_index1_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            },
            {
                "id": 200,
                "title": "Показатели полезности обращения в СЗН",
                "indices": [
                    {
                        "id": 100,
                        "title": "Динамика роста базы вакансий",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Количество вакансий за отчетный период",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index1_param1_current"
                            },
                            {
                                "id": 100,
                                "title": "Количество вакансий за отчетный период",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index1_param1_previous"
                            }
                        ],
                        "questionType": "DYNAMIC",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 200,
                        "title": "Проведение мероприятий, стимулирующих рост числа соискателей",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Ярмарка вакансий",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param1"
                            },
                            {
                                "id": 200,
                                "title": "Подача объявлений о ЦЗН в СМИ",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param2"
                            },
                            {
                                "id": 300,
                                "title": "Акции в социальных сетях",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param3"
                            },
                            {
                                "id": 400,
                                "title": "Дни общения с руководством региональной СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param4"
                            },
                            {
                                "id": 500,
                                "title": "Открытый отбор на вакансии конкретного востребованного работодателя",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param5"
                            },
                            {
                                "id": 600,
                                "title": "Деловые игры",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param6"
                            },
                            {
                                "id": 700,
                                "title": "Бизнес консультации по открытию собственного дела",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param7"
                            },
                            {
                                "id": 800,
                                "title": "День открытых дверей в СЗН с презентацией предоставляемых услуг и сервисов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param8"
                            },
                            {
                                "id": 900,
                                "title": "Экскурсии на предприятия региона",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param9"
                            },
                            {
                                "id": 1000,
                                "title": "И прочие мероприятия",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup2_index2_param10"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            },
            {
                "id": 300,
                "title": "Показатели, характеризующие минимизацию усилий клиента",
                "indices": [
                    {
                        "id": 100,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН в офисе ЦЗН",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Доступ к информационным стендам/стойкам беспрепятственный (прочесть объявления можно с близкого расстояния, стенды интуитивно легко найти / в ЦЗН есть навигация к инфостендам), в том числе для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param1"
                            },
                            {
                                "id": 200,
                                "title": "Информация на стендах актуальна на момент оценки по показателю (проверка на предмет актуальности осуществляется не менее чем 1 раз в 3 месяца)\nПоследняя дата проверки актуальности и обновления информации - указывается дата в АИС",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param2"
                            },
                            {
                                "id": 300,
                                "title": "Соблюдение единого оформления стендов в корпоративном стиле (предпочтительно в соответствии с брендбуком «Работа России») в каждом документе на стенде (шрифты, цвета, фирменные элементы и пр.).",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param3"
                            },
                            {
                                "id": 400,
                                "title": "Информация на инфостендах удобно сгруппирована и структурирована по тематическим разделам (информация различной тематики размещена в различных информационных полях (например, объявления о работе ЦЗН и реклама находятся в разных секциях)), есть заголовки",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param4"
                            },
                            {
                                "id": 500,
                                "title": "Информационные тексты стенда проанализированы на сайте https://glvrd.ru",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index1_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 200,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН на региональном сайте",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Сайт СЗН легко найти в популярных поисковиках (Яндекс, Google) (сайт находится в 3 первых позициях поисковой выдачи при введении запросов «ЦЗН региона», «центр занятости региона», «служба занятости региона»)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param1"
                            },
                            {
                                "id": 200,
                                "title": "На сайте имеется минимально необходимая информация о СЗН и ЦЗН, в т.ч. перечень и описание государственных услуг и сервисов в сфере занятости, режимы работы отделений и адреса, контактные данные СЗН и ЦЗН, ссылка на сайт «Работа в России», ссылка на сайт ОИВ, курирующих деятельность государственной службы занятости в  субъекте РФ",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param2"
                            },
                            {
                                "id": 300,
                                "title": "Пользователи сайта могут найти минимально необходимую информацию интуитивно и быстро, в т.ч. с помощью навигации",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param3"
                            },
                            {
                                "id": 400,
                                "title": "Сайт адаптирован для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param4"
                            },
                            {
                                "id": 500,
                                "title": "Информационные тексты сайта проанализированы на сайте https://glvrd.ru",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index2_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 300,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН в социальных сетях",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Аккаунты ЦЗН в социальных сетях открыты, не требуют подачу заявок и одобрения со стороны администраторов",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param1"
                            },
                            {
                                "id": 200,
                                "title": "Комментарии к публикациям открыты/есть возможность написать сообществу, при этом ЦЗН регулярно и своевременно отвечает на комментарии пользователей",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param2"
                            },
                            {
                                "id": 300,
                                "title": "Контент, в т.ч. картинки к постам, оформлен в фирменном стиле «Работа в России»",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param3"
                            },
                            {
                                "id": 400,
                                "title": "В неделю размещается не менее 3 постов в одной из социальных сетей (указать, в какой)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param4"
                            },
                            {
                                "id": 500,
                                "title": "Информационные тексты аккаунта социальной сети проанализированы на сайте https://glvrd.ru",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index3_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 400,
                        "title": "Доступность и понятность информации о работе, услугах и сервисах СЗН в контакт-центре",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Информация о контакт-центре доступна во всех каналах взаимодействия с гражданами (в офисе ЦЗН, на сайте, в социальных сетях), в том числе информация адаптирована для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param1"
                            },
                            {
                                "id": 200,
                                "title": "Дозвон до контакт-центра составляет не более 3-4 гудков. Количество попыток дозвона не превышает 3 раз. Подтверждается статистическими данными работы контакт-центра.",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param2"
                            },
                            {
                                "id": 300,
                                "title": "Наличие утвержденных сценариев (скриптов) для операторов контакт-центра по всем услугам и сервисам СЗН (с указанием даты утверждения/пересмотра). Сценарии должны проходить проверку на актуальность не реже, чем 1 раз в 3 месяца.",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param3"
                            },
                            {
                                "id": 400,
                                "title": "При соединении оператор первым поздоровался и представился, в т.ч. озвучил отделение ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param4"
                            },
                            {
                                "id": 500,
                                "title": "Количество шагов для получения консультации у определенного специалиста не превышает 2 (человеку, чтобы получить необходимую консультацию, нужно пройти не более 2 переключений между различными специалистами ЦЗН)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index4_param5"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 500,
                        "title": "Многоканальность и омниканальность взаимодействия (определяется наличие различных способов (каналов) взаимодействия с клиентом",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Форма обратной связи на региональном сайте СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param1"
                            },
                            {
                                "id": 200,
                                "title": "Онлайн-чат с консультантом на региональном сайте СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param2"
                            },
                            {
                                "id": 300,
                                "title": "Контакт-центр",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param3"
                            },
                            {
                                "id": 400,
                                "title": "Аккаунты в социальных сетях",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param4"
                            },
                            {
                                "id": 500,
                                "title": "Мессенджеры",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param5"
                            },
                            {
                                "id": 600,
                                "title": "Чат-боты",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param6"
                            },
                            {
                                "id": 700,
                                "title": "Книга жалоб и предложений (типовой вид для всех территориальных ЦЗН)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param7"
                            },
                            {
                                "id": 800,
                                "title": "Электронная почта региональной СЗН для группового оповещения/сбора обратной связи от клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param8"
                            },
                            {
                                "id": 1000,
                                "title": "СМС-информирование и иное (при направлении в адрес ФЦК СЗ ВНИИ труда описания канала взаимодействия, не входящего в перечень)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param9"
                            },
                            {
                                "id": 1100,
                                "title": "Иное (подтверждается описанием функционирования канала взаимодействия, не входящего в перечень)",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index5_param10"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 600,
                        "title": "Быстрое реагирование на запрос клиента",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Наличие в ЦЗН системы анализа показателей скорости обслуживания клиентов (замеров времени ожидания в очереди, времени оказания услуг)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup3_index6_param1"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 700,
                        "title": "Возможность предварительной записи на прием",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "В офисе ЦЗН через киоск электронной очереди",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param1"
                            },
                            {
                                "id": 200,
                                "title": "На сайте региональной СЗН через специальную форму",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param2"
                            },
                            {
                                "id": 300,
                                "title": "В аккаунтах ЦЗН в социальных сетях",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param3"
                            },
                            {
                                "id": 400,
                                "title": "Через контакт-центр СЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": true,
                                "parameterName": "indexGroup3_index7_param4"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            },
            {
                "id": 400,
                "title": "Показатели внешнего комфорта и удобства",
                "indices": [
                    {
                        "id": 100,
                        "title": "Внешний вид сотрудников",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Белый верх, черный или темно-синий низ униформы (п. 5.1 Брендбука) (блуза/рубашка с длинным рукавом, юбка / брюки прямого кроя, колготки телесного цвета, если туфли – то закрытые, для мужчин допустимы только классические ботинки)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index1_param1"
                            },
                            {
                                "id": 200,
                                "title": "Наличие платка для женщин, галстука/бабочки у мужчин, нагрудного значка с фирменным паттерном «Работа в России» (п. 5.1 Брендбука)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index1_param2"
                            },
                            {
                                "id": 300,
                                "title": "Макияж сотрудников не должен быть слишком ярким, используемые духи не должны иметь резкий запах; волосы должны быть аккуратно подстрижены и расчесаны или убраны в хвост; руки должны быть чистыми, ногти аккуратно подстрижены (разумной длины)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index1_param3"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 200,
                        "title": "Транспортная доступность",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Офис/точка присутствия находится в пешей доступности от остановок общественного транспорта (не более 15 минут)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param1"
                            },
                            {
                                "id": 200,
                                "title": "Наличие навигации к ЦЗН на остановках общественного транспорта/в значимых общественных местах",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param2"
                            },
                            {
                                "id": 300,
                                "title": "Наличие доступной парковки на территории ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index2_param3"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    },
                    {
                        "id": 300,
                        "title": "Комфортность условий для получения услуг и сервисов",
                        "parameters": [
                            {
                                "id": 100,
                                "title": "Оформление внутреннего помещения",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param1"
                            },
                            {
                                "id": 200,
                                "title": "Горизонтальная фасадная вывеска «Работа в России» размещена на фасаде",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param2"
                            },
                            {
                                "id": 300,
                                "title": "Информационная табличка (режим работы) размещена у входа в ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param3"
                            },
                            {
                                "id": 500,
                                "title": "Установлены скамейки",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param5"
                            },
                            {
                                "id": 600,
                                "title": "Установлены урны",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param6"
                            },
                            {
                                "id": 700,
                                "title": "Освещение фасада в соответствии с брендбуком",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param7"
                            },
                            {
                                "id": 800,
                                "title": "Уличная навигация",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param8"
                            },
                            {
                                "id": 900,
                                "title": "Информационная стелла установлена на улице",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param9"
                            },
                            {
                                "id": 1000,
                                "title": "Флаги «Работа в России» установлены снаружи здания ЦЗН",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param10"
                            },
                            {
                                "id": 1100,
                                "title": "Обустроена велопарковка",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param11"
                            },
                            {
                                "id": 1200,
                                "title": "Обустроен вход для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param12"
                            },
                            {
                                "id": 1300,
                                "title": "Размещены витринные постеры",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param13"
                            },
                            {
                                "id": 1400,
                                "title": "Отделка входной группы в бренде «Работа в России»",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param14"
                            },
                            {
                                "id": 1500,
                                "title": "Стойка администратора в бренде «Работа в России»",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param15"
                            },
                            {
                                "id": 1600,
                                "title": "Доступ для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param16"
                            },
                            {
                                "id": 1700,
                                "title": "Установлена электронная очередь",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param17"
                            },
                            {
                                "id": 1800,
                                "title": "Оборудован гардероб (для небольших ЦЗН – напольные вешалки)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param18"
                            },
                            {
                                "id": 1900,
                                "title": "Оборудована мини-зона ожидания при входе (зеркало, кресла)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param19"
                            },
                            {
                                "id": 2000,
                                "title": "Оборудована колясочная",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param20"
                            },
                            {
                                "id": 2100,
                                "title": "Компьютеры и столы размещены в цифровой зоне",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param21"
                            },
                            {
                                "id": 2200,
                                "title": "Стулья размещены в цифровой зоне",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param22"
                            },
                            {
                                "id": 2300,
                                "title": "Наличие тактильных индикаторов для граждан с инвалидностью в цифровой зоне",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param23"
                            },
                            {
                                "id": 2400,
                                "title": "Отделение цифровой зоны прозрачными перегородками",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param24"
                            },
                            {
                                "id": 2500,
                                "title": "Оформлены места для ожидания, в т.ч. для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param25"
                            },
                            {
                                "id": 2600,
                                "title": "Информационные стенды установлены в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param26"
                            },
                            {
                                "id": 2700,
                                "title": "Кулер установлен в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param27"
                            },
                            {
                                "id": 2800,
                                "title": "Кондиционер установлен в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param28"
                            },
                            {
                                "id": 2900,
                                "title": "Место приема пищи, кофе-поинт установлены в зоне ожидания",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param29"
                            },
                            {
                                "id": 3000,
                                "title": "Оборудован детский уголок",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param30"
                            },
                            {
                                "id": 3100,
                                "title": "Оборудован коворкинг для клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param31"
                            },
                            {
                                "id": 3200,
                                "title": "Оборудованы переговорные для клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param32"
                            },
                            {
                                "id": 3300,
                                "title": "Оформлены окна приема клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param33"
                            },
                            {
                                "id": 3400,
                                "title": "Организовано пространство для коллективной работы / конференц-зал для клиентов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param34"
                            },
                            {
                                "id": 3500,
                                "title": "Организована зона индивидуальной работы с гражданами",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param35"
                            },
                            {
                                "id": 3600,
                                "title": "Организована зона индивидуальной работы с гражданами с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param36"
                            },
                            {
                                "id": 3700,
                                "title": "Организован конференц-зал для сотрудников (отдельный)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param37"
                            },
                            {
                                "id": 3800,
                                "title": "Организована зона для групповых занятий и тренингов (отдельная)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param38"
                            },
                            {
                                "id": 3900,
                                "title": "Организованы кабинеты узких специалистов",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param39"
                            },
                            {
                                "id": 4000,
                                "title": "Туалет (раздельный)",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param40"
                            },
                            {
                                "id": 4100,
                                "title": "Туалет для граждан с инвалидностью",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param41"
                            },
                            {
                                "id": 4200,
                                "title": "Комната матери и ребенка",
                                "canUploadFile": true,
                                "onlyManagerCzn": false,
                                "parameterName": "indexGroup4_index3_param42"
                            }
                        ],
                        "questionType": "COMMENT",
                        "type": "CHECKLIST"
                    }
                ]
            }
        ]
    }
}
